import React from 'react'

import { ImgixGatsbyImage } from '@imgix/gatsby'

export default function MusicVideo({setShowVideo, setVideoLink}) {

  return (
    <>
      <div className="flex items-center justify-center space-x-2 md:space-x-4 cursor-pointer hover:opacity-60" onClick={() => {setShowVideo(true); setVideoLink("https://vimeo.com/881415996")}}>
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/music-video/7/1-min.png" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/music-video/7/2-min.png" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/music-video/7/3-min.png" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
      </div>

      <div className="pt-4 md:pt-6">
        <div className="flex items-center justify-center space-x-2 md:space-x-4 cursor-pointer hover:opacity-60" onClick={() => {setShowVideo(true); setVideoLink("https://vimeo.com/752670109")}}>
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/music-video/4/_1-min.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/music-video/4/_2-min.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/music-video/4/_3-min.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
        </div>
      </div>

      <div className="pt-4 md:pt-6">
        <div className="flex items-center justify-center space-x-2 md:space-x-4 cursor-pointer hover:opacity-60" onClick={() => {setShowVideo(true); setVideoLink("https://vimeo.com/777126014")}}>
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/music-video/3/_1-min.png" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/music-video/3/_2-min.png" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/music-video/3/_3-min.png" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
        </div>
      </div>

      <div className="pt-4 md:pt-6">
        <div className="flex items-center justify-center space-x-2 md:space-x-4 cursor-pointer hover:opacity-60" onClick={() => {setShowVideo(true); setVideoLink("https://vimeo.com/752705858")}}>
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/music-video/2/1-min.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/music-video/2/2-min.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/music-video/2/3-min.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
        </div>
      </div>

      <div className="pt-4 md:pt-6">
        <div className="hover:opacity-60 flex items-center justify-center space-x-1 md:space-x-2 cursor-pointer" onClick={() => {setShowVideo(true); setVideoLink("https://vimeo.com/806325885")}}>
        <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/music-video/5/3.png" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
        <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/music-video/5/2.png" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
        <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/music-video/5/1.png" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
        </div>
      </div>
      
      <div className="pt-4 md:pt-6">
        <div className="flex items-center justify-center space-x-1 md:space-x-2">
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/music-video/6/1.png" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/music-video/6/2.png" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/music-video/6/3.png" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
        </div>
      </div>

      <div className="pt-4 md:pt-6">
        <div className="flex items-center justify-center space-x-2 md:space-x-4 cursor-pointer hover:opacity-60" onClick={() => {setShowVideo(true); setVideoLink("https://vimeo.com/650796939")}}>
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/music-video/1/1.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/music-video/1/2.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/music-video/1/3.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
        </div>
      </div>
    </>
  )
}