import React from 'react'

import { ImgixGatsbyImage } from '@imgix/gatsby'

import "react-responsive-carousel/lib/styles/carousel.min.css"

import { Carousel } from 'react-responsive-carousel'

export default function VisualArt() {

  const carouselImages = [
    <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/visual-art/_1-min.png" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />,
    <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/visual-art/_2-min.png" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />,
    <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/visual-art/_3-min.png" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />,
    <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/visual-art/_4-min.png" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />,
  ]

  return (
    <>
      <div className="max-w-2xl">
        <Carousel showArrows={true} autoPlay={true} infiniteLoop={true} showStatus={false}>
          {carouselImages.map(image => (<div>{image}</div>))}
        </Carousel>
     </div>

      <div className="pt-4 md:pt-6">
        <div className="space-y-4">
          {/* Note: breakpoints of lines of text don't follow actual breakpoints set by tailwind automatically */}
          <p className="relative text-md text-justify mx-auto md:text-xl max-w-prose px-8 font-bold">
            2023 | Expanded Cinema Installation  | Loop
          </p>

          <p className="relative text-md text-justify mx-auto md:text-xl max-w-prose px-8">
            MEMORIA is an immersive four-channel expanded cinema installation with custom software and hardware elements. 
            It combines ethnographic research, science fiction, and recombinatory forms of cinema that appropriate found or pirated footage.
          </p>

          <p className="relative text-md text-justify mx-auto md:text-xl max-w-prose px-8">
            The work superimposes two narrative layers: the dystopian vision of William Gibson’s Johnny Mnemonic (1981), 
            one of the earliest cyberpunk short stories, and the ethnographic reality of Cuba’s “offline internet”, 
            the physical data distribution network El Paquete Semanal. 
            MEMORIA appropriates Gibson’s fictional story of a data courier who has crucial information locked in his head and 
            projects it onto the Cuban media reality with its informal networks and human infrastructures of data exchange and distribution.
          </p>

          <p className="relative text-md text-justify mx-auto md:text-xl max-w-prose px-8">
            MEMORIA is based on long-term ethnographic fieldwork on Cuban alternative data distribution networks. 
            With online access heavily restricted by the government, but also the US trade embargo, Cuba still has 
            one of the lowest internet penetration rates in the world. Yet its citizens have found a way to 
            distribute all kinds of web content in the form of the Paquete Semanal (The Weekly Package), 
            a one-terabyte collection of digital material compiled by a network of people with various forms of 
            privileged internet access. It is circulated nationwide on USB sticks and external hard drives via 
            an elaborate human infrastructure of deliverymen, so-called Paqueteros, who, like Johnny Mnemonic, 
            the main character in Gibson’s story, physically bring the content to the remotest corners of the 
            island and deliver right to their client’s homes. It forms a sort of offline version of the internet 
            and contains data such as the latest episodes of popular Netflix series, funny YouTube videos, 
            pop music albums, downloaded websites, celebrity social media content, anti-virus, and software. 
            Gibson’s work arrived 20 years later on the island via informal media distribution networks through which 
            foreign entertainment materials like novels that were left behind by Western travelers or sent into 
            the country by relatives from abroad were circulated. These networks were the precursors of what later 
            would become the Paquete Semanal.
          </p>

          
          <p className="relative text-md text-justify mx-auto md:text-xl max-w-prose px-8">
            To represent the memory loss of its protagonist, the work will slowly “die” over the course of the exhibition. 
            For this, we have developed a batch script that runs several programs that continuously manipulate the video. 
            Over time the video devours itself through the constant process of faulty replication, like cancer that makes 
            the cells inside the host grow in a random and uncontrolled way. The speed at which the material deteriorates 
            is adjusted to the duration of the work’s exhibition.
          </p>

          
          <p className="relative text-md text-justify mx-auto md:text-xl max-w-prose px-8">
            All hardware is housed in a custom case, a sculptural object in itself that was molded and 3D printed from 
            recycled plastic filaments in collaboration with COPINCHA, a hackerspace in Havana that works with local materials and technologies.
          </p>

          
          <p className="relative text-md text-justify mx-auto md:text-xl max-w-prose px-8">
            <span className=" font-bold">
              CAST <br />
            </span>
            Dayron Villalon <br />
            Chris Gómez Gónzalez
          </p>

          <p className="relative text-md text-justify mx-auto md:text-xl max-w-prose px-8">
            <span className=" font-bold">
              CREW <br />
            </span>
            Directed by Steffen Köhn <br />
            in collaboration with Nestor Siré and Erick Mota <br />
            Cinematographer  | Nicole Medvecka <br />
            Costumes |  Raki Fernandez <br />
            CGI & VFX  | Alexander Bley <br />
            Editor  |  Ginés Olivares, Miguel Coyula <br />
            Sound design  | Lorenz Fischer & Malte Audick <br />
            Music  |  Johannes Klingebiel
          </p>
          <p className="relative text-md text-justify mx-auto md:text-xl max-w-prose px-8">
            Screenings: <br />
            AKSIOMA Institute for Contemporary Art / Ljubljana, Slovenia. <br />
            HONG-GAH Museum / Taipei, Taiwan.
          </p>

        </div>
      </div>
    </>
  )
}