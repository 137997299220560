import React, { useState } from 'react'
import ReactPlayer from 'react-player'

import { RiCloseLine } from 'react-icons/ri'

import Wrapper from '../components/Wrapper'
import Film from '../components/Film'
import Commercial from '../components/Commercial'
import MusicVideo from '../components/MusicVideo'
import VisualArt from '../components/VisualArt'

const IndexPage = () => {

  const [selectedLink, setSelectedLink] = useState("FILM")
  const [showVideo, setShowVideo] = useState(false)
  const [videoLink, setVideoLink] = useState("")
  const selectedClass = "font-bold text-md md:text-lg"
  const links = ["FILM", "COMMERCIAL", "MUSIC VIDEO", "VISUAL ART"]

  return (
    <>
      {showVideo ?
        <div className="relative px-4 pt-20">
          <div className="absolute right-2 md:right-16 cursor-pointer text-yellowstone">
            <RiCloseLine size="2em" onClick={() => {setShowVideo(false); setVideoLink("")}} />
          </div>
          <div className="flex justify-center h-full pt-2 xs:pt-12 sm:mt-20">
            <ReactPlayer
              className=""
              url={videoLink}
              controls
            />
          </div>
        </div>
        :
        <Wrapper>
          <div className="flex flex-col">
            <div className="flex flex-row space-x-6 justify-center pt-2 md:pt-6">
              {links.map(link => (
                <div className={(selectedLink === link ? selectedClass : "") + " hover: cursor-pointer"} onClick={() => setSelectedLink(link)}>
                  {link}
                </div>
              ))}
            </div>

            <div className="flex-grow min-h-screen pt-10 px-2 mx-auto max-w-7xl space-y-2 md:px-5 lg:px-20 md:space-y-8 ">
              {selectedLink === "FILM" &&
                <Film setShowVideo={setShowVideo} setVideoLink={setVideoLink} />
              }
              {selectedLink === "COMMERCIAL" &&
                <Commercial setShowVideo={setShowVideo} setVideoLink={setVideoLink} />
              }
              {selectedLink === "MUSIC VIDEO" &&
                <MusicVideo setShowVideo={setShowVideo} setVideoLink={setVideoLink} />
              }
              {selectedLink === "VISUAL ART" &&
                <VisualArt />
              }
            </div>
          </div>
        </Wrapper>
      }
    </>
  )
}

export default IndexPage