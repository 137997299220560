import React from 'react'

import { ImgixGatsbyImage } from '@imgix/gatsby'

export default function Commercial({setShowVideo, setVideoLink}) {
  
  return (
    <>
      <div className="">
        <div className="hover:opacity-60 flex items-center justify-center space-x-2 md:space-x-4 cursor-pointer" onClick={() => {setShowVideo(true); setVideoLink("https://vimeo.com/896523232")}}>
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/commercial/5/_1-min.png" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/commercial/5/_2-min.png" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/commercial/5/_3-min.png" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
        </div>
      </div>

      <div className="pt-4 md:pt-6">
        <div className="hover:opacity-60 flex items-center justify-center space-x-1 md:space-x-2 cursor-pointer" onClick={() => {setShowVideo(true); setVideoLink("https://vimeo.com/764572431")}}>
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/commercial/4/_1-min.png" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/commercial/4/_2-min.png" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/commercial/4/_3-min.png" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
        </div>
      </div>

      <div className="pt-4 md:pt-6">
        <div className="hover:opacity-60 flex items-center justify-center space-x-1 md:space-x-2 cursor-pointer" onClick={() => {setShowVideo(true); setVideoLink("https://vimeo.com/702342991")}}>
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/commercial/1/1.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/commercial/1/2.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/commercial/1/3.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
        </div>
      </div>

      <div className="py-4 md:py-6">
        <div className="hover:opacity-60 flex items-center justify-center space-x-2 md:space-x-4 cursor-pointer" onClick={() => {setShowVideo(true); setVideoLink("https://vimeo.com/704814584")}}>
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/commercial/3/1.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/commercial/3/2.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/commercial/3/3.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
        </div>
      </div>
      
      <div className="hover:opacity-60 space-y-4 cursor-pointer" onClick={() => {setShowVideo(true); setVideoLink("https://vimeo.com/888700077")}}>
        <div className="flex items-center justify-center space-x-2 md:space-x-4">
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/commercial/2/1.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/commercial/2/2.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/commercial/2/3.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
        </div>
        <div className="flex items-center justify-center space-x-2 md:space-x-4">
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/commercial/2/4.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/commercial/2/5.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/commercial/2/6.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
        </div>
      </div>
    </>
  )
}