import React from 'react'

import { ImgixGatsbyImage } from '@imgix/gatsby'

export default function Film({setShowVideo, setVideoLink}) {

  return (
    <>
      <div>
        <div className="space-y-2 md:space-y-4">
          <div className="flex items-center justify-center space-x-2 md:space-x-4">
            <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/narrative/1/1.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
            <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/narrative/1/2.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
            <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/narrative/1/3.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          </div>
          <div className="flex items-center justify-center space-x-2 md:space-x-4">
            <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/narrative/1/4.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
            <ImgixGatsbyImage className="pointer-events-none" alt=""  src="https://nicole-mn.imgix.net/narrative/1/5.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
            <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/narrative/1/6.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          </div>
        </div>
      </div>

      <div className="pt-4 md:pt-6">
        <div className="flex items-center justify-center space-x-2 md:space-x-4 cursor-pointer hover:opacity-60" onClick={() => {setShowVideo(true); setVideoLink("https://vimeo.com/891167148/2c9bad9d31")}}>
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/narrative/4/1.png" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/narrative/4/2.png" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/narrative/4/3.png" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
        </div>
      </div>
      
      <div className="py-4 md:py-6">
        <div className="space-y-2 md:space-y-4">
          <div className="hover:opacity-60 cursor-pointer flex items-center justify-center space-x-2 md:space-x-4" onClick={() => {setShowVideo(true); setVideoLink("https://vimeo.com/690029578/ee55f25308")}}>
            <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/narrative/2/1.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
            <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/narrative/2/2.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
            <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/narrative/2/3.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          </div>
          <div className="hover:opacity-60 cursor-pointer flex items-center justify-center space-x-2 md:space-x-4" onClick={() => {setShowVideo(true); setVideoLink("https://vimeo.com/690032966/c1d69cb71d")}}>
            <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/narrative/2/4.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
            <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/narrative/2/5.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
            <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/narrative/2/6.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          </div>
          <div className="hover:opacity-60 cursor-pointer flex items-center justify-center space-x-2 md:space-x-4" onClick={() => {setShowVideo(true); setVideoLink("https://vimeo.com/690036142/fc296d3d9a")}}>
            <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/narrative/2/7.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
            <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/narrative/2/8.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
            <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/narrative/2/9.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          </div>
        </div>
      </div>

      <div>
        <div className="hover:opacity-60 cursor-pointer flex items-center justify-center space-x-4" onClick={() => {setShowVideo(true); setVideoLink("https://vimeo.com/733270811/e9561a9b4c")}}>
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/narrative/3/1.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/narrative/3/2.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
          <ImgixGatsbyImage className="pointer-events-none" alt="" src="https://nicole-mn.imgix.net/narrative/3/3.jpg" layout="constrained" aspectRatio={16 / 9} imgixParams={{ fit: 'crop', width: '400' }} />
        </div>
      </div>
    </>
  )
}